import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {
  COUPON_CODE_OFFERS,
  COUPON_CODE_APPLY_ON,
  getCouponCodeApplyOnLabel,
  getCouponCodeOfferLabel,
} from '../../config/coupon-codes-offers.config';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import {
  formatDateToUtc,
  formatTimestampToUtc,
} from '../../utils/datetime.util';
import generateCouponCode from '../../utils/coupon-code-generate.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Datetimepicker from '../form-elements/datetimepicker.component';
import Channels from '../form-elements/channels.component';
import CouponCodeApplyOn from '../form-elements/coupon-code-apply-on.component';
import CouponCodeOffers from '../form-elements/coupon-code-offers.component';
import Status from '../form-elements/status.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/coupon-codes.service';
import StyledDialogActions from '../../theme/dialogActionStyles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditCouponCode = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      code: '',
      validFrom: null,
      validTo: null,
      channels: [],
      usageLimit: 0,
      validForType: COUPON_CODE_APPLY_ON[4],
      offerOption: COUPON_CODE_OFFERS[0],
      offerOptionValue: '',
      status: 1,
      notes: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [disableFields, setDisableFields] = useState(false);

  const handleGenerateCodeManually = () => {
    const code = generateCouponCode();
    setValue('code', code);
  };

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New coupon code created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('Coupon code details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      code: data.code,
      validFrom: formatDateToUtc(data.validFrom),
      validTo: formatDateToUtc(data.validTo),
      usageLimit: Number(data.usageLimit),
      validForType: data.validForType.id,
      offerOption: data.offerOption.id,
      offerOptionValue: Number(data.offerOptionValue),
      status: Number(data.status),
      channels: [],
      notes: data.notes || '',
    };
    (data.channels || []).forEach((c) => {
      payload.channels.push(Number(c.id));
    });

    if (dataId) {
      delete payload.code;
      updateExistingData(payload);
    } else {
      payload.password = data.password;

      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        setValue('code', res.data?.code || '');
        if (res.data?.validFrom) {
          setValue('validFrom', formatTimestampToUtc(res.data.validFrom));
        }
        if (res.data?.validTo) {
          setValue('validTo', formatTimestampToUtc(res.data.validTo));
        }
        setValue('usageLimit', res.data?.usageLimit || 0);
        setValue('validForType', {
          id: res.data?.validForType || '',
          title: getCouponCodeApplyOnLabel(res.data?.validForType || ''),
        });
        setValue('offerOption', {
          id: res.data?.offerOption || '',
          title: getCouponCodeOfferLabel(res.data?.offerOption || ''),
        });
        setValue('offerOptionValue', res.data?.offerOptionValue || 1);
        setValue('status', Number(res.data?.status || 0));
        setValue('notes', res.data?.notes || '');

        const channelsAccess = [];
        (res.data?.channels || []).forEach((c) => {
          channelsAccess.push({
            id: c.id || '',
            code: c.code || '',
            title: c.title || '',
          });
        });
        setValue('channels', channelsAccess);

        if (res.data?.isUsed) {
          setDisableFields(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="code"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Coupon Code *"
                      type="text"
                      error={!!errors.code}
                      helperText={errors?.code?.message || null}
                      fullWidth
                      variant="filled"
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {!dataId && (
                  <Button
                    type="button"
                    variant="text"
                    onClick={handleGenerateCodeManually}
                  >
                    Generate Random Code
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="validFrom"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="cc_valid_from"
                      name="cc_valid_from"
                      label="Valid From"
                      defaultValue={value}
                      clearable
                      onChange={onChange}
                      error={errors?.validFrom?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="validTo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="cc_valid_to"
                      name="cc_valid_to"
                      label="Valid To"
                      defaultValue={value}
                      clearable
                      onChange={onChange}
                      error={errors?.validTo?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="offerOption"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <CouponCodeOffers
                      id="offer_options"
                      name="offer_options"
                      label="Offer Type *"
                      defaultValue={value}
                      multiple={false}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.offerOption?.message || ''}
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="offerOptionValue"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Offer Value *"
                      type="number"
                      error={!!errors.offerOptionValue}
                      helperText={errors?.offerOptionValue?.message || null}
                      fullWidth
                      variant="filled"
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="channels"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Channels
                      id="cc_channels"
                      name="cc_channels"
                      label="Select Channels"
                      defaultValue={value}
                      multiple
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      disabled={disableFields}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="usageLimit"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Usage Limit *"
                      type="number"
                      error={!!errors.usageLimit}
                      helperText={errors?.usageLimit?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="validForType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <CouponCodeApplyOn
                      id="offer_apply_on"
                      name="offer_options"
                      label="Offer Applicable On *"
                      defaultValue={value}
                      multiple={false}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.validForType?.message || ''}
                      disabled={disableFields}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="cc_status"
                      name="cc_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[0, 1]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="notes"
                  control={control}
                  rules={{ ...maxLengthValidation(200) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Notes"
                      type="text"
                      error={!!errors.notes}
                      helperText={errors?.notes?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditCouponCode.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditCouponCode;
