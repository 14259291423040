// Define coupon offer types
export const COUPON_CODE_OFFERS = [
  {
    id: 'PER_OFF',
    title: 'Percentage (%) Discount',
  },
  {
    id: 'AMT_OFF',
    title: 'Fixed Amount Discount',
  },
  {
    id: 'NUM_OF_DAYS_TRIAL',
    title: 'Days Free Trial',
  },
  {
    id: 'NUM_OF_DAYS_FREE',
    title: 'Days Free Access (Gift)',
  },
];

export const getCouponCodeOfferLabel = (val) => {
  let label = '';

  COUPON_CODE_OFFERS.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

export const COUPON_CODE_APPLY_ON = [
  {
    id: 'SVOD',
    title: 'SVOD (Monthly & Yearly) Only',
  },
  {
    id: 'SVOD_MONTHLY',
    title: 'SVOD Monthly Only',
  },
  {
    id: 'SVOD_YEARLY',
    title: 'SVOD Yearly Only',
  },
  {
    id: 'TVOD',
    title: 'TVOD Only',
  },
  {
    id: 'SVOD_TVOD',
    title: 'Both SVOD (Monthly & Yearly) and TVOD',
  },
];

export const getCouponCodeApplyOnLabel = (val) => {
  let label = '';

  COUPON_CODE_APPLY_ON.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};
