import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Stack,
  Button,
  Divider,
  Typography,
  TextField,
} from '@mui/material';
import { STATUS_ALL_VALUE } from '../../config/const.config';
import Iconify from '../common/iconify.component';
import Scrollbar from '../common/scrollbar.component';
import Channels from '../form-elements/channels.component';
import Status from '../form-elements/status.component';
import CouponCodeApplyOn from '../form-elements/coupon-code-apply-on.component';
import CouponCodeOffers from '../form-elements/coupon-code-offers.component';

const FilterCouponCodes = ({ values, onCancel, onSuccess, onClear }) => {
  const [filters, setFilters] = useState(values);

  const handleApplyFilters = () => {
    setTimeout(() => {
      onSuccess(filters);
    }, 250);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          Filters
        </Typography>
        <IconButton onClick={onCancel}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>

      <Divider />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <form id="list-data-filters" onSubmit={(e) => e.preventDefault()}>
          <Stack sx={{ p: 2 }}>
            <TextField
              id="fl_search"
              name="fl_search"
              label="Search"
              variant="filled"
              defaultValue={filters.search}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  search: e.target.value,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <Channels
              id="fl_channels"
              name="fl_channels"
              label="Channels"
              defaultValue={filters.channels}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  channels: v,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <CouponCodeApplyOn
              id="fl_apply_on"
              name="fl_apply_on"
              label="Applicable On"
              defaultValue={filters.applyOn}
              multiple={false}
              onChange={(v) => {
                setFilters({
                  ...filters,
                  applyOn: v,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <CouponCodeOffers
              id="fl_offer"
              name="fl_offer"
              label="Offer"
              defaultValue={filters.offer}
              multiple={false}
              onChange={(v) => {
                setFilters({
                  ...filters,
                  offer: v,
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <Status
              id="fl_status"
              name="fl_status"
              label="Status"
              defaultValue={
                filters.status === null ? STATUS_ALL_VALUE : filters.status
              }
              multiple={false}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  status:
                    e.target.value === STATUS_ALL_VALUE ? null : e.target.value,
                });
              }}
              validOptions={[0, 1]}
              sx={{ width: '100%', my: 1 }}
              allOption
            />
          </Stack>
        </form>
      </Scrollbar>

      <Divider />

      <Stack spacing={1} sx={{ p: 2 }}>
        <Button
          fullWidth
          form="list-data-filters"
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          fullWidth
          type="button"
          color="secondary"
          variant="contained"
          startIcon={<Iconify icon="ic:round-clear-all" />}
          onClick={onClear}
        >
          Clear All
        </Button>
      </Stack>
    </>
  );
};

FilterCouponCodes.propTypes = {
  values: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default FilterCouponCodes;
