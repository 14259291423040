import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Coupon codes module configurations
export const LISTING_COLUMNS = [
  {
    id: 'code',
    label: 'Code',
    width: '20%',
    align: 'left',
    dataKey: 'code',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'offer',
    label: 'Offer',
    width: '40%',
    align: 'left',
    dataKey: 'offer',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'usageLimitText',
    label: 'Usage Limit',
    width: '10%',
    align: 'center',
    dataKey: 'usageLimitText',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
    conditional: (val) => !val.isUsed,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'code',
    label: 'Code',
    dataKey: 'code',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'validFrom',
    label: 'Valid From',
    dataKey: 'validFrom',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'validTo',
    label: 'Valid To',
    dataKey: 'validTo',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'assignedToChannels',
    label: 'Valid For Channels',
    dataKey: 'assignedToChannels',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'usageLimitText',
    label: 'Usage Limit',
    dataKey: 'usageLimitText',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'validForType',
    label: 'Offer Applicable On',
    dataKey: 'validForType',
    type: CELL_DATA_TYPES.COUPON_APPLICABLE_ON,
  },
  {
    id: 'offer',
    label: 'Offer',
    dataKey: 'offer',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
